<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="items"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      row-key="id"
    >
      <div slot="status" slot-scope="status, record">
        <div v-if="['part_allocated', 'all_allocated'].includes(record.status_slug)">
          <a @click="showAllocationRecordModal(record.id)">{{ status }}</a>
        </div>
        <div v-else>
          {{ status }}
        </div>
      </div>

      <div slot="item_no" slot-scope="item_no, record">
        <div v-if="isShowMenu('agents/purchase_orders')">
          <a @click="showInfoModal(record.id)">{{ item_no }}</a>
        </div>
        <div v-else>
          {{ item_no }}
        </div>
      </div>
    </a-table>

    <!-- 详情模态框 -->
    <show-agent-purchase-item
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <agent-self-purchase-item-allocation-records
      v-if="isShowAllocationRecordsModal"
      :visible.sync="isShowAllocationRecordsModal"
      :id="showingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentSelfPurchaseItemsList',
  components: {
    ShowAgentPurchaseItem: () => import('@/views/agent_purchase_items/agent/Show.vue'),
    AgentSelfPurchaseItemAllocationRecords: () => import('@/views/agent_purchase_items/AllocationRecord')
  },
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: true,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      isShowInfoModal: false,
      isShowAllocationRecordsModal: false,
      showingId: 0
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '项目状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          fixed: 'left'
        },
        {
          title: '项目编号',
          dataIndex: 'item_no',
          scopedSlots: { customRender: 'item_no' },
          fixed: 'left'
        },
        {
          title: '套餐名称',
          dataIndex: 'product_name',
          ellipsis: true
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '卡品',
          dataIndex: 'card_type'
        },
        {
          title: '周期数',
          dataIndex: 'service_period'
        },
        {
          title: '采购卡数(张)',
          dataIndex: 'card_count'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatCurrency
        },
        {
          title: '卡板价格',
          dataIndex: 'card_type_price'
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatCurrency
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatCurrency
        },
        {
          title: '小计(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 150,
          ellipsis: true
        }
      ]
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    showAllocationRecordModal(recordId) {
      this.showingId = recordId
      this.isShowAllocationRecordsModal = true
    },

    fetchData() {
      this.$emit('completed')
    }
  }
}
</script>
