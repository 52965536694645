<template>
  <div>
    <search-agent-self-purchase-order @submit="submitSearch" />

    <a-spin :spinning="loading">
      <div v-if="isDataExist">
        <div
          v-for="order in data"
          :key="order.id"
        >
          <div class="simiot-card-box" style="margin-bottom: 20px">
            <a-row class="order-header">
              <a-col :span="16">
                <a-row>
                  <a-col :span="8">
                    <div v-if="isShowMenu('agents/purchase_orders')">
                      <a @click="showInfoModal(order.id)">{{ order.order_no }}</a>
                    </div>
                    <div v-else>
                      {{ order.order_no }}
                    </div>
                  </a-col>
                </a-row>

                <a-row>
                  <a-col :span="8">
                    {{ order.created_at }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.status }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.pickup_way }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.card_count }}
                  </a-col>
                  <a-col :span="4">
                    ￥ {{ order.order_fee | formatCurrency }}
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <agent-self-purchase-items-list
              :items="order.agent_purchase_items"
              :loading="loading"
              @completed="fetchData"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <a-empty />
      </div>
    </a-spin>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agent-self-purchase-order
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchAgentSelfPurchaseOrder from '@/views/agent_purchase_orders/agent/Search'
import AgentSelfPurchaseItemsList from '@/views/agent_purchase_items/agent/index'
import { findAgentPurchaseOrders } from '@/api/agent_purchase_order'
import { hasPermission } from '@/utils/permission'
export default {
  name: 'AgentSelfPurchaseOrderList',
  components: {
    Pagination,
    SearchAgentSelfPurchaseOrder,
    AgentSelfPurchaseItemsList,
    ShowAgentSelfPurchaseOrder: () => import('@/views/agent_purchase_orders/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      showingId: 0, // 正在显示详情的订单id
      isShowInfoModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '项目编号',
          dataIndex: 'id'
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    },
    isDataExist() {
      return this.data.length > 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentPurchaseOrders(Object.assign(
        { agent_id: this.agentId }, this.query)
      ).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.order-header {
  margin-bottom: 5px;
  color: #8b8b8b;

  .agent-name {
    color: #5d5d5d;
  }
}
</style>
